@import '../../assets/scss/_vars.scss';

.formats {
  // bem
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 428px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__inner {
    @media (max-width: 428px) {
      background-color: $graybgc;
      border-radius: 40px;
      padding: 32px 16px;
    }
  }

  &__top {
    display: flex;

    text-align: center;


    align-items: center;
    margin: 0 auto;

    @media (min-width: 820px) {
      flex-direction: row;
      margin-bottom: 24px;
      align-items: flex-end;
    }
  }

  &__title {
    flex: 1 1 auto;
    font-weight: 700;
    font-size: 27px;
    line-height: 30px;
    //font-size: 36px;
    //line-height: 43px;
    margin-bottom: 16px;

    @media (min-width: 428px) {
      font-size: 48px;
      line-height: 58px;
    }

    @media (min-width: 820px) {
      font-size: 40px;
      line-height: 46px;
      margin-bottom: 0px;
      text-align: left;
    }

    @media (min-width: 1024px) {
      font-size: 64px;
      line-height: 77px;
    }


  }

  &__button {
    font-size: 24px;
    line-height: 24px;
    color: $white;
    border-radius: 16px;
    background-color: $main;
    padding: 16px 26px;
    display: none;

    span {
      font-size: inherit;
      line-height: inherit;
    }

    @media (min-width: 428px) {
      display: inline-block;
    }
    @media (min-width: 820px) {
      font-size: 20px;
      padding: 12px 24px;
    }
    @media (min-width: 1024px) {
      padding: 16px 32px;
    }


  }

  &__button-mobile {
    font-size: 24px;
    line-height: 24px;
    color: $white;
    border-radius: 16px;
    background-color: $main;
    padding: 16px 32px;
    width: 100%;
    display: none;
    text-transform: lowercase;

    span {
      font-size: inherit;
      line-height: inherit;
    }

    @media (max-width: 428px) {
      display: block;
      margin-bottom: 32px;
    }
  }

  &__subtitle {
    font-size: 18px;
    line-height: 24px;
    color: $grayText;
    letter-spacing: 0;

    text-align: center;
    margin: 0 auto 32px;


    @media (min-width: 428px) {
      margin-bottom: 32px;
      //max-width: 60%;
    }
    @media (min-width: 820px) {
      text-align: left;
    }
    @media (min-width: 1024px) {
      margin-bottom: 36px;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;

    @media (max-width: 1024px) {
      column-gap: 16px;
    }

    @media (max-width: 428px) {
      display: none;
    }
  }

  &__item {
  }

  &__image-wrapper {
    position: relative;
    height: 580px;
    width: 100%;
    margin-bottom: 36px;

    @media (max-width: 1024px) {
      margin-bottom: 24px;
      height: 420px;
    }

    @media (max-width: 428px) {
      position: relative;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;
    }
  }

  &__image {
    border-radius: 16px;
    overflow: hidden;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__bottom {
    @media (max-width: 428px) {
      display: flex;
      position: absolute;
      bottom: 24px;
      left: 24px;
      align-items: center;
    }
  }

  &__carousel {
    display: none;

    @media (max-width: 428px) {
      display: block;
    }
  }

  &__icon {
    position: absolute;
    bottom: 32px;
    left: 32px;
    width: 88px;
    height: 88px;

    @media (max-width: 1024px) {
      width: 60px;
      height: 60px;
      bottom: 24px;
      left: 24px;
    }

    @media (max-width: 428px) {
      position: static;
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }

  &__t360 {
    position: absolute;
    bottom: 22px;
    right: 42px;
    //left: 32px;
    width: 88px;
    height: 88px;
    color: white;
    font-size: 62px;

    @media (max-width: 1024px) {
      width: 48px;
      height: 32px;
      font-size: 24px;
      right: 16px;
      bottom: 36px;
    }

    @media (max-width: 428px) {
      width: 32px;
      height: 32px;
      font-size: 24px;
      right: 32px;
      bottom: 18px;
    }
  }

  &__item-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0;

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 24px;
    }

    @media (max-width: 428px) {
      color: $white;
      font-size: 16px;
      line-height: 16px;
    }
  }
}

.formatsSwiper {
  width: calc(100% + 32px);
  max-width: 1024px;
  overflow: clip;
  overflow-y: visible;
  height: 100%;

  @media (max-width: 428px) {
    overflow-x: visible;
  }
}

.swiper-slide:first-child {
  margin-left: 3px;
}

.formatsSwiper .swiper-slide {
  height: 536px;
  width: 300px;
}
