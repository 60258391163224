@import '../../assets/scss/_vars.scss';

.partners {
  // bem
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 428px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__inner {
    @media (max-width: 428px) {
      background-color: $graybgc;
      border-radius: 40px;
      padding: 32px 16px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 24px;
    text-align: center;
    max-width: 70%;
    margin: 0 auto 24px;

    @media (min-width: 428px) {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 24px;
    }
    @media (min-width: 1024px) {
      font-size: 64px;
      line-height: 77px;
      margin-bottom: 40px;
    }
  }

  &__elems {
    display: grid;
    grid-template-areas:
      'a a b'
      'c d d'
      'c e f';
    gap: 20px;
    grid-template-columns: 33.33333%;

    @media (max-width: 1024px) {
      gap: 16px;
    }

    @media (max-width: 428px) {
      display: block;
    }
  }

  &__elem {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    min-height: 284px;
    background: white;

    &:nth-child(1) {
      grid-area: a;
    }
    &:nth-child(2) {
      grid-area: b;
    }
    &:nth-child(3) {
      grid-area: c;
    }
    &:nth-child(4) {
      grid-area: d;
    }
    &:nth-child(5) {
      grid-area: e;
    }
    &:nth-child(6) {
      grid-area: f;
    }

    @media (max-width: 428px) {
      min-height: auto;

      &:not(:last-child) {
        margin-bottom: 12px;
        padding: 24px;
      }
    }
  }

  &__elem-text {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 32px;
    flex: 1 1 auto;
    letter-spacing: normal;

    @media (max-width: 1024px) {
      margin-bottom: 27px;
    }

    @media (max-width: 428px) {
      margin-bottom: 24px;
    }
  }

  &__elem-bottom {
    display: flex;
  }

  &__elem-left {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__elem-author {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__elem-city {
    letter-spacing: normal;
    font-size: 24px;
    line-height: 29px;
    color: $grayText;

    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__elem-avatar {
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(0, 0, 0, 0.16);
    width: 40px;
    height: 40px;

    @media (min-width: 428px) {
      width: 48px;
      height: 48px;
    }
    @media (min-width: 1024px) {
      width: 72px;
      height: 72px;
    }
  }
}
