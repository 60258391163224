@import '../../assets/scss/_vars.scss';

.carousel {
  // bem

  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $white;
  height: 746px;

  @media (min-width: 820px) {

  }
  @media (min-width: 1024px) {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: $graybgc;
    height: 638px;
  }

  &__inner {
    height: 578px;
    background-color: $graybgc;
    border-radius: 40px;
    padding: 32px 16px;
    flex-direction: column;

    @media (min-width: 1280px) {
      flex-direction: row;
      background-color: transparent;
    }
  }

  &__elem {
    display: flex;
    position: relative;
  }

  &__left {
    flex: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 428px) {
      flex: 0 0 550px;
      margin-right: 0;
    }
    @media (min-width: 820px) {
      flex: auto;
    }
    @media (min-width: 1024px) {
      flex: 0 0 738px;
      margin-right: 73px;
      align-items: inherit;
    }
    @media (min-width: 1280px) {
      margin-right: 0px;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    left: 0;
    justify-content: center;
    position: absolute;
    bottom: 174px;
    right: 4px;

    @media (min-width: 428px) {

    }
    @media (min-width: 1024px) {
      right: 0;
      bottom: -59px;
      position: absolute;
    }
    @media (min-width: 1280px) {
      left: auto;
      bottom: 0px;
      bottom: 190px;
    }
  }

  &__title {
    font-weight: 700;
    text-align: center;
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 16px;
    color: $grayText;

    @media (min-width: 428px) {
      margin-bottom: 8px;
      font-size: 48px;
      line-height: 58px;
    }

    @media (min-width: 1024px) {
      font-size: 64px;
      line-height: 77px;
    }

    @media (min-width: 1024px) {
      font-size: 48px;
      text-align: left;
    }


  }

  &__title-second {
    font-weight: 700;
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 24px;

    @media (min-width: 428px) {
      font-size: 48px;
      line-height: 58px;
    }
    @media (min-width: 1024px) {
      font-size: 64px;
      line-height: 77px;
    }
    @media (min-width: 1280px) {
      font-size: 56px;
    }

  }

  &__elem-subtitle {
    letter-spacing: normal;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 24px;
    min-height: 52px;
    display: none;
    position: relative;
    top: 110px;
    align-items: center;

    @media (min-width: 428px) {

    }
    @media (min-width: 820px) {

    }
    @media (min-width: 1024px) {
      display: flex;
      margin-bottom: 32px;
      top: 0px;
    }


  }

  &__button-mobile {
    display: block;
    //font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: $white;
    width: 80%;

    position: absolute;
    bottom: 28px;
    left: 10%;
    right: 10%;
    text-transform: lowercase;

    @media (min-width: 428px) {

    }
    @media (min-width: 820px) {

    }
    @media (min-width: 1024px) {
      display: none;
    }

    span {
      padding: 16px 16px;
      display: block;
      position: relative;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      background-color: $main;
      border-radius: 16px;
      text-align: center;
    }


  }

  &__button {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: $white;
    display: none;
    width: 398px;
    position: relative;
    top: 110px;
    text-transform: lowercase;

    @media (min-width: 428px) {

    }
    @media (min-width: 1024px) {
      display: block;
      width: 413px;
    }
    @media (min-width: 1024px) {
      display: flex;
      width: auto;
    }
    span {
      padding: 16px 16px;
      display: block;
      position: relative;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      background-color: $main;
      border-radius: 16px;
    }

  }

  &__image {

    //height: 560px;
    object-fit: cover;
    width: 156px;
    @media (min-width: 428px) {
      width: 170px;
    }
    @media (min-width: 1024px) {
      width: 276px;
    }
  }

  &__left-icon,
  &__right-icon {
    cursor: pointer;
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $main;
    justify-content: center;
    align-items: center;
    top: 0px;
    display: none;

    @media (min-width: 428px) {

    }
    @media (min-width: 1024px) {
      display: flex;
      top: 20px;
    }


  }

  &__left-icon {
    margin-right: 10px;
    svg {
      position: relative;
      left: -2px;
    }
    @media (min-width: 428px) {
      margin-right: 35px;
    }
    @media (min-width: 1024px) {
      margin-right: 24px;
    }
  }

  &__right-icon {

    margin-left: 10px;
    svg {
      position: relative;
      right: -2px;
    }
    @media (min-width: 428px) {
      margin-left: 35px;
    }
    @media (min-width: 1024px) {
      margin-left: 24px;
    }
  }
}

.carouserSwiper {
  overflow-y: visible;
  overflow-x: clip;

  // @media (max-width: 428px) {
  //   overflow-x: visible;
  // }
}

.swiper-pagination {
  position: absolute;
  width: auto !important;
  margin: 0;
  bottom: 139px !important;
  right: 0px !important;
  left: 0px !important;

  @media (min-width: 428px) {
    //bottom: -93px !important;
    //right: 117px !important;
    //width: auto !important;
    //left: auto !important;
  }

  @media (min-width: 1024px) {
    bottom: -33px !important;
    right: 188px !important;
    left: auto !important;
  }

  @media (min-width: 1280px) {
    right: 155px !important;
    bottom: 162px !important;
  }

}
.swiper-pagination-bullet {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  // background-color: $grayInput;
  background-color: red;
}
.swiper-pagination-bullet-active {
  width: 32px;
  height: 8px;
  border-radius: 24px;
  background-color: $main;
}

.carouserSwiper .swiper-slide:first-child {
  margin-left: 0px;
}

.carouserSwiper .swiper-slide {
  width: 396px;
  height: 660px;
  @media (min-width: 428px) {

  }
}
