@import '../../assets/scss/_vars.scss';

.achievements {
  // bem
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 428px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__inner {
    @media (max-width: 428px) {
      background-color: $graybgc;
      border-radius: 40px;
      padding: 32px 16px;
    }
  }

  &__top {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: 428px) {
      align-items: flex-end;
    }
    @media (min-width: 720px) {
      flex-direction: row;
    }
    @media (min-width: 1024px) {
      margin-bottom: 16px;
      align-items: flex-start;
      flex-direction: row;
    }

  }

  &__title {
    flex: 1 1 auto;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    margin-bottom: 16px;

    @media (min-width: 428px) {
      font-size: 48px;
      line-height: 58px;
      max-width: 550px;
    }
    @media (min-width: 768px) {
      font-size: 40px;
      line-height: 46px;
      margin-bottom: 0px;
      text-align: left;
      margin-bottom: 0px;
    }
    @media (min-width: 1024px) {
      font-size: 64px;
      line-height: 77px;
      text-align: left;
    }

    @media (min-width: 1280px) {
      line-height: 57px;
    }
  }

  &__button {
    background-color: $main;
    border-radius: 16px;
    padding: 16px 34px;
    font-size: 22px;
    line-height: 24px;
    color: $white;
    //font-weight: 700;
    width: 100%;
    text-transform: lowercase;

    span {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    }

    @media (min-width: 428px) {
      padding: 16px 24px;
    }
    @media (min-width: 768px) {
      display: inline-block;
      width: auto;
    }
    @media (min-width: 1024px) {

    }
    @media (min-width: 1280px) {
      margin-top: 60px;
      width: auto;
    }
  }

  &__subtitle {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 40px;
    color: $grayText;
    letter-spacing: normal;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__subtitle-mobile {
    display: none;
    color: $grayText;
    letter-spacing: normal;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 32px;
    text-align: center;

    @media (max-width: 428px) {
      display: block;
    }
  }

  &__elems {
  }

  &__elem {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    padding: 24px;
    width: 306px;
    display: flex;
    flex-direction: column;
  }

  &__elem-content {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 22px;
    flex: 1 1 auto;
    letter-spacing: normal;
  }

  &__elem-date {
    font-size: 16px;
    line-height: 16px;
    color: $grayText;
    letter-spacing: normal;
  }
}

.achievementsSwiper {
  width: calc(100% + 80px + 24px);
  max-width: calc(1440px - 80px);
  overflow: clip;
  overflow-y: visible;
  height: 100%;


  &::before {
    content: '';
    z-index: 5;
    position: absolute;
    top: 0;
    right: -2px;
    height: 100%;
    width: calc(80px + 24px);
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @media (max-width: 428px) {
    width: calc(100% + 16px);
    overflow-x: visible;

    &::before {
      display: none;
    }
  }
}

.achievementsSwiper .swiper-slide:first-child {
  margin-left: 3px;
}

.achievementsSwiper .swiper-slide {
  margin-left: 4px;
  margin-top: 4px;
  height: auto;
  background: white;

  @media (max-width: 428px) {
    width: 306px;
  }
}
