@import 'vars';

* {
  font-family: DINRoundPro, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.022em;
}

body {
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  min-width: 320px;
  color: $black;
  position: relative;
  font-size: 14px;
  line-height: 1;
  margin: 0;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
}

main {
  flex: 1 0 auto;
  // height: 20vh;
}

// .main {
//   width: 100vw;

//   @media (max-width: 1365px) {
//     // .section--colored {
//     //   background-color: transparent;
//     // }

//     & > .section:first-child {
//       .colored {
//         display: block;
//       }

//       .section__content {
//         padding: 111px 17px;
//       }
//     }

//     & > .section:last-child {
//       margin-bottom: 0px;

//       .section__content {
//         padding-bottom: 235px;
//       }
//     }
//   }
// }

.footer {
  flex: 0 0 auto;
}

.container {
  max-width: calc(1280px + (60px * 2));
  padding-right: 60px;
  padding-left: 60px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 428px) {
    padding-right: 16px;
    padding-left: 16px;
    max-width: 428px;
  }
}
