@font-face {
  font-family: 'DINRoundPro';
  src: url('../fonts/DINRoundPro/DINRoundPro-Black.eot');
  src: local('DINRoundPro-Black'),
  url('../fonts/DINRoundPro/DINRoundPro-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DINRoundPro/DINRoundPro-Black.woff2') format('woff2'),
  url('../fonts/DINRoundPro/DINRoundPro-Black.woff') format('woff'),
  url('../fonts/DINRoundPro/DINRoundPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'DINRoundPro';
  src: url('../fonts/DINRoundPro/DINRoundPro-Medi.eot');
  src: local('DINRoundPro-Medi'),
  url('../fonts/DINRoundPro/DINRoundPro-Medi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DINRoundPro/DINRoundPro-Medi.woff2') format('woff2'),
  url('../fonts/DINRoundPro/DINRoundPro-Medi.woff') format('woff'),
  url('../fonts/DINRoundPro/DINRoundPro-Medi.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DINRoundPro';
  src: url('../fonts/DINRoundPro/DINRoundPro-Light.eot');
  src: local('DINRoundPro-Light'),
  url('../fonts/DINRoundPro/DINRoundPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DINRoundPro/DINRoundPro-Light.woff2') format('woff2'),
  url('../fonts/DINRoundPro/DINRoundPro-Light.woff') format('woff'),
  url('../fonts/DINRoundPro/DINRoundPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DINRoundPro';
  src: url('../fonts/DINRoundPro/DINRoundPro.eot');
  src: local('DINRoundPro'),
  url('../fonts/DINRoundPro/DINRoundPro.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DINRoundPro/DINRoundPro.woff2') format('woff2'),
  url('../fonts/DINRoundPro/DINRoundPro.woff') format('woff'),
  url('../fonts/DINRoundPro/DINRoundPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINRoundPro';
  src: url('../fonts/DINRoundPro/DINRoundPro-Bold.eot');
  src: local('DINRoundPro-Bold'),
  url('../fonts/DINRoundPro/DINRoundPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DINRoundPro/DINRoundPro-Bold.woff2') format('woff2'),
  url('../fonts/DINRoundPro/DINRoundPro-Bold.woff') format('woff'),
  url('../fonts/DINRoundPro/DINRoundPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

* {
  font-family: DINRoundPro, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.022em;
}
