@import '../../assets/scss/_vars.scss';

.grow {
  // bem
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 428px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__inner {
  }

  &__title {
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    margin-bottom: 32px;

    @media (max-width: 1024px) {
      font-size: 48px;
      line-height: 58px;
    }

    @media (max-width: 428px) {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 24px;
    }
  }

  &__elems {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px 20px;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__elems-mobile {
    display: none;

    @media (max-width: 1024px) {
      display: block;
    }
  }

  &__elem {
    display: flex;
    flex-direction: column;
    padding: 32px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
  }

  &__elem-title {
    letter-spacing: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    margin-bottom: 8px;
    color: $main;

    @media (max-width: 428px) {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 4px;
    }
  }

  &__elem-subtitle {
    letter-spacing: normal;
    color: $grayText;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
    flex: 1 1 auto;
    margin-bottom: 26px;

    @media (max-width: 428px) {
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 50px;
      @media (max-width: 428px) {
        width: calc(100% + 16px);

        &::before {
          display: none;
        }
      }
    }
  }

  &__elem-tag {
    letter-spacing: normal;
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    color: $main;
    display: flex;

    span {
      position: relative;
      top: 1px;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      padding: 16px 24px;
      background-color: $mainLight;
      border-radius: 40px;
    }
  }
}

.growSwiper {
  width: calc(100% + 60px);
  max-width: 1024px;
  overflow: clip;
  overflow-y: visible;
  height: 100%;

  &::before {
    content: '';
    z-index: 5;
    position: absolute;
    top: 0;
    right: -2px;
    height: 100%;
    width: calc(80px + 24px);
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @media (max-width: 428px) {
    width: calc(100% + 16px);
    overflow-x: visible;

    &::before {
      display: none;
    }
  }
}

.growSwiper .swiper-slide:first-child {
  margin-bottom: 3px;
}

.growSwiper .swiper-slide {
  height: auto;
  width: 630px;
  margin-left: 4px;
  margin-top: 4px;

  @media (max-width: 428px) {
    width: 424px;
  }
}
