@import '../../assets/scss/_vars.scss';

.circs {
  // bem
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 428px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__inner {
  }

  &__title {
    font-weight: 700;
    font-size: 43px;
    line-height: 58px;
    margin-bottom: 32px;
    display: none;

    @media (min-width: 428px) {

    }

    @media (min-width: 1024px) {
      font-size: 64px;
      line-height: 77px;
    }


  }

  &__elems {
  }

  &__elem {
    display: flex;
    flex-direction: column;
    padding: 32px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    width: 300px;
    margin-right: 20px;
  }

  &__elem-title {
    letter-spacing: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 58px;
    margin-bottom: 8px;
    color: $main;

    @media (min-width: 1024px) {
      font-size: 64px;
      line-height: 64px;
    }
    @media (min-width: 1280px) {
      font-size: 50px;
    }
  }

  &__elem-subtitle {
    letter-spacing: normal;
    color: $grayText;
    font-weight: 400;
    flex: 1 1 auto;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;

    @media (min-width: 1024px) {
      margin-bottom: 26px;
      font-size: 36px;
      line-height: 36px;
    }

    @media (min-width: 1280px) {
      font-size: 32px;
    }

  }

  &__elem-tag {
    letter-spacing: normal;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: $main;
    display: flex;

    span {
      display: block;
      position: relative;
      top: 10px;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      padding: 16px 24px;
      background-color: $mainLight;
      border-radius: 40px;

      @media (max-width: 1024px) {
        top: 0px;
      }
    }
  }
}

.circsSwiper {
  padding-left: 0;
  z-index: 1;
  overflow: clip;
  overflow-y: visible;
  overflow-x: visible;
  height: 100%;
  width: calc(80%);
  margin-left: 5px;

  &::before {
    display: none;
  }

  @media (min-width: 428px) {
    width: calc(100% + 80px + 24px);
    overflow: clip;
    overflow-y: visible;
    overflow-x: visible;
    height: 100%;

    &::before {
      content: '';
      z-index: 5;
      position: absolute;
      top: 0;
      right: -2px;
      height: 100%;
      width: calc(80px + 24px);
      background: linear-gradient(
                      270deg,
                      rgba(255, 255, 255, 1) 0%,
                      rgba(255, 255, 255, 0) 100%
      );
    }
  }

  @media (min-width: 1024px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: visible;
    list-style: none;
    max-width: calc(1440px - 80px);
  }


}

.circsSwiper .swiper-slide {
  width: 306px;
  height: 240px;

  @media (min-width: 428px) {
    width: 424px;
  }
}
