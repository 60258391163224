@import '../../assets/scss/_vars.scss';

.recall {
  // bem
  padding-top: 100px;
  padding-bottom: 74px;
  background-color: $graybgc;

  @media (max-width: 428px) {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $white;
  }

  &__inner {
    display: flex;

    @media (max-width: 428px) {
      background-color: $graybgc;
      border-radius: 40px;
      padding: 32px 16px;
      flex-direction: column;
    }
  }

  &__left {
    flex: 0 1 630px;
    margin-right: 20px;

    @media (max-width: 1024px) {
      flex: 0 1 444px;
    }

    @media (max-width: 428px) {
      flex: auto;
      margin-right: 0px;
      text-align: center;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    margin-bottom: 16px;

    @media (max-width: 1024px) {
      font-size: 48px;
      line-height: 58px;
    }

    @media (max-width: 428px) {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    letter-spacing: normal;
    line-height: 16px;
    color: $grayText;
    font-size: 15px;
    margin-bottom: 24px;

    @media (min-width: 428px) {
      line-height: 24px;
    }

    @media (min-width: 1024px) {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 32px;
    }


  }

  &__right {
    width: 100%;
    min-height: auto;
    max-width: 445px;

    @media (min-width: 428px) {
      width: 50%;
      min-height: 672px;
    }
    @media (min-width: 1024px) {
      max-width: 522px;
      width: auto;
    }
    @media (min-width: 1280px) {
      min-height: 502px;
    }


  }

  &__form {
  }

  &__input,
  &__textarea {
    padding: 10px 24px;
    width: 100%;
    background-color: $white;
    border: 2px solid $grayInput;
    border-radius: 16px;
    margin-bottom: 12px;
    color: $black;
    font-size: 18px;
    line-height: 28px;

    &::placeholder {
      color: $grayInput;
    }
  }

  &__textarea {
    overflow: auto;
    outline: none;
    resize: none;
    margin-bottom: 24px;
    height: 148px;

    @media (max-width: 1024px) {
      height: 156px;
    }

    @media (max-width: 428px) {
      height: 240px;
    }
  }

  &__button {
    width: 100%;
    padding: 16px;
    //font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 16px;
    background-color: $main;
    border-radius: 16px;
    color: $white;

    span {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__icon {
    margin-bottom: 16px;
    width: 88px;
    height: 88px;
  }

  &__complete {
    text-align: center;
  }

  &__complete-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 24px;

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 16px;
    }

    @media (max-width: 428px) {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 24px;
    }
  }

  &__terms {
    font-size: 12px;
    color: $grayText;

    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 19px;
      text-align: center;
    }

    @media (max-width: 1024px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__link {
    color: inherit;
    font-size: inherit;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $grayText;
      bottom: -2px;
      left: 0;
      right: 0;
    }

    @media (max-width: 1024px) {
      display: block;
      width: fit-content;
      margin: 0 auto;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $grayText;
        bottom: -2px;
        left: 0;
        right: 0;
      }
    }
  }
}
