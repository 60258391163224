@import '../../assets/scss/_vars.scss';

.benefits {
  // bem
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: $graybgc;

  @media (max-width: 428px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__inner {
  }

  &__elems {
    padding-left: 108px;
    padding-right: 108px;

    @media (max-width: 1024px) {
      padding-left: 0px;
      padding-right: 0px;
      max-width: 80%;
    }

    @media (max-width: 428px) {
      max-width: none;
    }
  }

  &__elem {
    &:not(:last-child) {
      margin-bottom: 48px;

      @media (max-width: 1024px) {
        margin-bottom: 32px;
      }

      @media (max-width: 428px) {
        margin-bottom: 24px;
      }
    }
  }

  &__icon {
    margin-bottom: 16px;
    width: 88px;
    height: 88px;

    @media (max-width: 428px) {
      margin-bottom: 8px;
      width: 48px;
      height: 48px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    margin-bottom: 24px;

    @media (max-width: 1024px) {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 14px;
    }

    @media (max-width: 428px) {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    color: $grayText;

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 29px;
    }

    @media (max-width: 428px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
