@import '../assets/scss/_vars.scss';

.header {
  padding: 14px 0px;
  background: $graybgcheader;
  width: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;

  @media (max-width: 1024px) {
    padding: 24px 0px;
  }

  @media (max-width: 428px) {
    padding: 0px 0px;
  }

  &__inner {
    display: flex;
  }

  &__logo-wrapper {
    padding: 12px 23px 12px 0px;
    flex: 1 1 auto;

    &.sv {
      padding: 25px 23px 25px 0px;
    }
  }

  &__logo {
    width: 125px;
    height: 28px;

    &.sv {
      width: 135px;
      height: 19px;
    }

    @media (max-width: 1024px) {
      width: 104px;
      height: 24px;
    }

    @media (max-width: 428px) {
      width: 125px;
      height: 28px;
    }
  }

  &__nav {
    padding-top: 10px;
    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__nav-elems {
    display: flex;
    margin-right: 69px;
  }

  &__nav-elem {
    &:not(:last-child) {
      margin-right: 17px;
    }
  }

  &__nav-link,
  &__button {
    cursor: pointer;
    color: $main;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__nav-link {
    display: block;
    padding: 16px 32px;

    span {
      position: relative;
    }

    &:hover {
      span {
        &::before {
          content: '';
          width: 100%;
          height: 2px;
          background-color: $main;
          position: absolute;
          bottom: -3px;
        }
      }
    }
  }

  &__button {
    display: none;

    span {
      position: relative;
      top: -1px;
    }

    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
      padding: 15px 32px 14px 32px;
      position: relative;
      background-color: $white;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      height: 56px;
      margin-top: 8px;
    }
  }

  &__button-menu {
    display: flex;
    align-items: center;
    position: relative;
    background-color: $white;
    padding: 14px 18px;
    border-radius: 16px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.2);
    height: 32px;
    top: 18px;
    font-size: 12px;

    span {
      position: relative;
      top: -1px;
      color: $main;
      margin-right: 11px;
      font-size: 14px;

      @media (min-width: 1024px) {
        top: 12px;
      }
    }

    @media (min-width: 1024px) {
      display: none;
    }


  }

  &__button-icon {
    display: inline;
    margin-left: 10px;
  }

  &__menu {
    z-index: 99999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
  }

  &__menu-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__menu-top {
    margin-top: 24px;
    display: flex;
  }

  &__menu-logo-wrapper {
    padding: 12px 23px 12px 0px;
    flex: 1 1 auto;

    @media (min-width: 428px) {
      padding: 12px 23px 12px 23px;
    }
  }

  &__menu-logo {
    height: 22px;

    @media (min-width: 428px) {
      width: 104px;
      height: auto;
    }
  }

  &__menu-button-mobile {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    position: relative;
    background-color: $graybgc;
    border-radius: 16px;

    span {
      color: $main;
      font-size: 16px;
      line-height: 16px;
    }
  }

  &__button-mobile-icon {
    display: inline;
    margin-left: 10px;
  }

  &__content-elems {
    margin-top: 24px;

    @media (max-width: 428px) {
      margin-top: 47px;
      width: calc(100% + 32px);
      position: relative;
      left: -16px;
    }
  }

  &__content-elem {
    padding: 16px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $grayInput;
  }

  &__content-link {
    color: $black;
    font-weight: 700;
    font-size: 24px;
    line-height: 23px;
  }

  &__content-icon {
  }

  &__content-phones {
    max-width: 396px;
    width: 100%;
    border-radius: 16px;
    background-color: $graybgc;
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: auto;

    @media (min-width: 428px) {
      margin-top: 24px;
      margin-bottom: 13px;
      margin-left: calc(50% - 198px);
    }
  }

  &__content-number {
    font-size: 36px;
    line-height: 36px;
    color: $black;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    @media (max-width: 428px) {
      font-size: 30px;
      line-height: 30px;
    }
  }

  &__content-number-icon {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }

  &__content-telegram {
    font-size: 24px;
    line-height: 24px;
    color: $grayText;
    display: flex;
    align-items: center;
  }

  &__content-telegram-icon {
    margin-left: 8px;
    margin-right: 8px;
    width: 24px;
    height: 24px;

    path {
      fill: $grayText;
    }
  }

  &__content-button {
    max-width: 396px;
    width: 100%;
    //font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: $white;
    padding: 16px 68px;
    border-radius: 16px;
    background-color: $main;
    margin-top: auto;
    margin-bottom: 32px;

    span {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    @media (min-width: 428px) {
      margin-left: calc(50% - 196px);
    }
  }
}

.footer {
  background-color: $main;
  border-radius: 40px 40px 0px 0px;

  &__inner {
    padding: 24px 0px;

    @media (max-width: 428px) {
      padding: 40px 0px;
    }
  }

  &__top {
    display: flex;
    padding-top: 0px;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 768px) {
      align-items: flex-start;
    }
    @media (min-width: 1024px) {
      align-items: center;
      padding-top: 24px;
    }
    @media (min-width: 1280px) {
      align-items: flex-start;
      flex-direction: row;
    }

  }

  &__logo {
    width: 197px;
    height: 44px;
    flex: 1 1 auto;
    text-align: start;
  }

  &__logo-icon {
    width: 50vw;

    @media (min-width: 428px) {
      width: auto;
      height: 100%;
    }
  }

  &__apps-link {
    display: block;

    &:not(:last-child) {
      margin-right: 16px;

      @media (max-width: 1024px) {
        margin-right: 24px;
      }

      @media (max-width: 428px) {
        margin-right: 14px;
      }
    }
  }

  &__apps {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 32px;

    @media (min-width: 1024px) {
      width: auto;
      margin-top: 0px;
    }
  }

  &__apps-button {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    background-color: $white;
    padding: 16px;
    color: $black;
    position: relative;
    margin-right: 32px;
    display: flex;
    align-items: center;

    @media (max-width: 428px) {
      justify-content: center;
      padding: 16px 0px;
      margin-right: 16px;
      max-width: 50%;
      width: 100%;
    }
  }

  &__apps-button-icon {
    margin-left: 8px;
  }

  &__apps-icon {
    width: 48px;
    height: 48px;
  }

  &__navigation {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid transparentize($white, 1 - 0.76);

    @media (min-width: 1024px) {
      margin-top: 24px;
      border-top: none;
    }
    @media (min-width: 1280px) {
      margin-top: 38px;
      margin-bottom: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  &__navigation-elems {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
      justify-content: flex-start;
      flex-wrap: wrap;
      max-width: 85%;
    }

    @media (max-width: 428px) {
      max-width: 100%;

      &:nth-child(2n) {
        margin-left: auto;
      }
    }
  }

  &__navigation-elem {
    min-width: 132px;
    text-align: center;

    @media (max-width: 428px) {
      width: 50%;

      &:nth-child(2n) {
        margin-left: auto;
      }
    }
  }

  &__navigation-link {
    cursor: pointer;
    display: block;
    color: $white;
    line-height: 19px;
    padding: 16px 8px;
    margin-right: 0px;
    text-align: left;
    font-size: 22px;
    text-transform: lowercase;

    @media (min-width: 428px) {
      text-align: left;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 16px;
      margin-right: 32px;
    }

    @media (min-width: 1024px) {
      padding: 16px;
    }

    @media (min-width: 1280px) {
      font-size: 16px;
    }


  }

  &__contacts {
    display: flex;
    padding: 32px 0px;
    margin: 24px 0px;
    border-top: 1px solid transparentize($white, 1 - 0.76);
    border-bottom: 1px solid transparentize($white, 1 - 0.76);
    flex-direction: column;
    margin-bottom: 25px;

    @media (min-width: 428px) {
      margin-top: 17px;
      margin-bottom: 22px;
    }
    @media (min-width: 1024px) {
      flex-direction: row;
      margin-top: 17px;
      margin-bottom: 22px;
    }
  }

  &__contacts-left {
    //display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    @media (min-width: 1280px) {
      flex-direction: row;
    }
  }

  &__phone-number {
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    color: $white;
    letter-spacing: 0.002em;

    @media (max-width: 1024px) {
      font-size: 48px;
      line-height: 48px;
    }

    @media (max-width: 428px) {
      font-size: 36px;
      line-height: 36px;
    }
  }

  &__mails {
    margin-right: 40px;
    display: flex;

    @media (max-width: 1024px) {
      margin-top: 24px;
      flex-direction: column;
    }

    @media (max-width: 428px) {
      margin-top: 31px;
    }
  }

  &__mails-link {
    letter-spacing: 0.002em;
    display: flex;
    align-items: center;
    color: $white;
    font-size: 24px;
    line-height: 24px;

    &:not(:last-child) {
      margin-right: 16px;
    }

    @media (max-width: 1024px) {
      &:not(:first-child) {
        margin-top: 24px;
      }
    }

    span {
      letter-spacing: inherit;
      font-size: inherit;
      line-height: inherit;
      margin-left: 8px;
    }
  }

  &__mails-link-icon {
    width: 48px;
    height: 48px;

    @media (max-width: 428px) {
      width: 24px;
      height: 24px;
    }
  }

  &__buttons {
    display: flex;

    @media (max-width: 428px) {
      margin-top: 24px;
    }
  }

  &__button {
    width: 48px;
    height: 48px;
    border-radius: 16px;
    background-color: $white;
    text-align: center;
    vertical-align: center;
    padding-top: 6px;

    &:not(:last-child) {
      margin-right: 24px;

      @media (max-width: 428px) {
        margin-right: 16px;
      }
    }

    @media (max-width: 1024px) {
      width: 56px;
      height: 56px;
    }

    @media (max-width: 428px) {
      width: 48px;
      height: 48px;
    }
  }

  &__bottom {
    display: flex;
    padding-bottom: 24px;

    @media (max-width: 428px) {
      flex-direction: column;
      padding-bottom: 0px;
    }
  }

  &__copyright {
    color: $white;

    line-height: 24px;
    letter-spacing: 0.002em;
    font-size: 15px;

    @media (min-width: 428px) {
      font-size: 22px;
    }
    @media (min-width: 1024px) {
      font-size: 24px;
    }
  }

  &__contract {
    @media (max-width: 428px) {
      margin-top: 8px;
    }
  }

  &__contract-link {
    color: $grayInput;
    font-size: 24px;
    line-height: 24px;
    position: relative;
    letter-spacing: 0.002em;
    margin-left: 55px;

    @media (max-width: 428px) {
      font-size: 16px;
      margin-left: 0px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $grayInput;
    }
  }
}
