@import '../../assets/scss/_vars.scss';

.news {
  $block: &;
  // bem
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: $graybgc;

  @media (max-width: 428px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__inner {
  }

  &__top {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;

    @media (max-width: 428px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    flex: 1 1 auto;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;

    @media (min-width: 428px) {
      font-size: 48px;
      line-height: 58px;
    }
    @media (min-width: 820px) {
      line-height: 48px;
    }
    @media (min-width: 1024px) {
      font-size: 64px;
      line-height: 77px;
      margin-bottom: 8px;
    }
  }

  &__button {
    background-color: $main;
    border-radius: 16px;
    padding: 16px 26px;
    font-size: 24px;
    line-height: 24px;
    color: $white;
    //font-weight: 700;
    width: 100%;
    text-align: center;
    text-transform: lowercase;

    span {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    }

    @media (min-width: 428px) {
    }
    @media (min-width: 820px) {
      width: 390px;
    }

    @media (min-width: 1280px) {
      display: flex;
      width: auto;
    }
  }

  &__subtitle-mobile {
    font-size: 20px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 32px;
    color: $grayText;
    text-align: center;
    letter-spacing: normal;
    display: block;

    @media (min-width: 428px) {
      display: none;
    }
  }

  &__subtitle {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 40px;
    color: $grayText;
    letter-spacing: normal;

    @media (max-width: 1024px) {
      max-width: 400px;
      margin-bottom: 24px;
    }

    @media (max-width: 428px) {
      display: none;
    }
  }

  &__elems {
  }

  &__elem {
    background-color: $white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    padding: 24px;
    overflow: hidden;
    position: relative;

    #{$block}__elem-button {
      //background-color: #9fcfac;
      color: $black;
    }

    #{$block}__elem-icon {
      path {
        //fill: #005e68;
      }
    }


  }

  &__elem-top {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: 428px) {
      margin-bottom: 22px;
    }
  }

  &__elem-img {
    width: 72px;
    height: 72px;
    object-fit: cover;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    margin-right: 16px;

    @media (max-width: 428px) {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
  }

  &__elem-right {
  }

  &__elem-title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 3px;
    font-weight: 700;

    @media (min-width: 428px) {
      font-size: 24px;
      line-height: 29px;
    }
    @media (min-width: 1280px) {
      font-size: 22px;
    }
  }

  &__elem-date {
    font-size: 20px;
    color: $grayText;

    @media (max-width: 428px) {
      font-size: 16px;
      line-height: 16px;
    }
  }

  &__elem-content-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 8px;
    letter-spacing: normal;

    @media (max-width: 428px) {
      line-height: 24px;
    }
  }

  &__elem-content {
    font-size: 24px;
    line-height: 29px;
    letter-spacing: normal;
    position: relative;

    // &:before {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 150px;
    //   background: linear-gradient(
    //     0deg,
    //     rgba(255, 255, 255, 1) 0%,
    //     rgba(255, 255, 255, 0) 100%
    //   );
    // }
  }

  &__elem-button {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 32px;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;

    span {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      margin-right: 8px;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      top: -150px;
      left: 0;
      width: 100%;
      height: 150px;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 25%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    @media (max-width: 428px) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__elem-icon {
    width: 38px;
    height: 38px;
    position: relative;
    // top: 5px;

    path {
      fill: red;
    }

    @media (max-width: 428px) {
      width: 24px;
      height: 24px;
      top: 0px;
    }
  }
}

.newsSwiper {
  width: calc(100% + 80px + 24px);
  max-width: calc(1440px - 80px);
  overflow: clip;
  overflow-y: visible;
  overflow-x: visible;
  height: 100%;

  &::before {
    content: '';
    z-index: 5;
    position: absolute;
    top: 0;
    right: -2px;
    height: 100%;
    width: calc(80px + 24px);
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @media (max-width: 428px) {
    width: calc(100% + 16px);

    &::before {
      display: none;
    }
  }
}

.newsSwiper .swiper-slide {
  width: 413px;
  height: 492px;
  margin-left: 4px;
  margin-top: 4px;

  @media (max-width: 428px) {
    width: 300px;
    height: 426px;
  }
}
