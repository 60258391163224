@import '../../assets/scss/_vars.scss';

.banner {
  // bem
  padding-top: 116px;
  background-color: $graybgc;
  position: relative;

  @media (max-width: 1024px) {
    padding-top: 105px;
  }

  @media (max-width: 428px) {
    padding-top: 72px;
  }

  &__inner{
    height: calc(100vh - 69px);

    @media (min-width: 1280px) {
      height: auto;
    }

    &.container{

    }
  }

  &__top {
    text-align: center;
    position: absolute;
    left: 16px;
    right: 16px;
    width: calc(100% - 32px);
    height: calc(100vh - 69px - 20px);
    border-radius: 15px;
    //padding-top: calc(100% - 50px);
    padding-top: calc(100vh - 69px - 20px - 230px);

    background: linear-gradient(transparent, 30%, rgba(0, 0, 0, 0.7294117647)), url('../../assets/img/sv/sv_main.jpg');
    background-position: center;
    background-size: cover;
    background-position-x: 82%;

    @media (min-width: 820px) {
      height: calc(100vh - 117px - 20px);
      padding-top: calc(100% - 117px);
    }

    @media (min-width: 1280px) {
      background: none;
      position: relative;
      padding-top: 0px;
      height: calc(100% - 72px);
    }
  }

  &__title {
    color: $white;
    line-height: 26px;
    margin-bottom: 25px;
    font-size: 21px;
    padding: 0px 12px;
    font-weight: 300;

    @media (min-width: 390px) {
      font-size: 22px;
    }
    @media (min-width: 414px) {
      font-size: 24px;
    }
    @media (min-width: 428px) {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 24px;
    }

    @media (min-width: 820px) {
      font-size: 50px;
    }

    @media (min-width: 1024px) {
      font-size: 64px;
      line-height: 77px;
      margin-bottom: 40px;
    }
    @media (min-width: 1280px) {
      color: $black;
      font-size: 48px;
      font-weight: bold;
      width: 960px;
      line-height: 56px;
      margin-left: calc(50% - 480px);
    }
  }

  &__button {
    background: $main;
    border-radius: 16px;
    padding: 16px 32px;
    color: $white;
    //font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 48px;
    text-transform: lowercase;

    span {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    @media (max-width: 1024px) {
      margin-bottom: 32px;
    }

    @media (max-width: 428px) {
      width: 85%;
      padding: 16px 0px;
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  }

  &__image {
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    height: 700px;
    object-fit: cover;
    display: none;
    height: calc(100vh - 100px);
    border-radius: 16px;

    @media (min-width: 428px) {
      height: 496px;
    }

    @media (min-width: 1280px) {
      display: block;
    }

  }
}
