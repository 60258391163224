@import '../../assets/scss/_vars.scss';

.flex {
  // bem
  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: 428px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media (min-width: 1280px) {
    padding-top: 0px;
    padding-bottom: 100px;
  }

  &__inner {
  }

  &__title {
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    margin-bottom: 16px;

    @media (max-width: 1024px) {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 8px;
    }

    @media (max-width: 428px) {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 0px;
    }
  }

  &__subtitle {
    letter-spacing: normal;
    font-size: 24px;
    line-height: 24px;
    color: $grayText;
    margin-bottom: 40px;

    @media (max-width: 1024px) {
      margin-bottom: 32px;
    }

    @media (max-width: 428px) {
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 28px;
    }
  }

  &__text {
    letter-spacing: normal;
    font-weight: 700;
    color: $grayLight;
    font-size: 33px;
    line-height: 38px;

    &:not(:last-child) {
      margin-bottom: 48px;
    }

    b {
      letter-spacing: inherit;
      font-weight: inherit;
      font-size: inherit;
      color: $black;
    }

    @media (min-width: 428px) {
      font-size: 48px;
      line-height: 58px;
    }
  }

  &__link {
    letter-spacing: normal;
    font-weight: inherit;
    font-size: inherit;
    display: block;
    color: $grayText;
    text-align: left;
  }
}
