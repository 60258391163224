@import '../../assets/scss/_vars.scss';

.terms {
  // bem
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: $graybgc;

  @media (max-width: 428px) {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  &__inner {
    display: flex;

    @media (max-width: 428px) {
      flex-direction: column;
    }
  }

  &__left {
    width: 50%;
    margin-right: 20px;

    @media (max-width: 1024px) {
      margin-right: 16px;
    }

    @media (max-width: 428px) {
      margin-right: 0px;
      width: 100%;
    }
  }

  &__left-title {
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    margin-bottom: 22px;

    @media (max-width: 1024px) {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 8px;
    }

    @media (max-width: 428px) {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 16px;
      max-width: 80%;
    }
  }

  &__left-subtitle {
    font-size: 24px;
    line-height: 24px;
    color: $grayText;
    margin-bottom: 32px;

    @media (max-width: 428px) {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 32px;
    }
  }

  &__button-mobile {
    display: none;
    //font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: $white;
    padding: 16px 68px;
    border-radius: 16px;
    background-color: $main;
    width: 100%;
    text-transform: lowercase;

    span {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    @media (max-width: 428px) {
      display: block;
    }
  }

  &__button {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: $white;
    padding: 16px 68px;
    border-radius: 16px;
    background-color: $main;

    span {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    @media (max-width: 1024px) {
      padding: 16px 32px;
    }

    @media (max-width: 428px) {
      display: none;
    }
  }

  &__right {
    width: 50%;

    @media (max-width: 428px) {
      display: none;
    }
  }

  &__elems {
  }

  &__elem {
    background: $white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.2);
    padding: 24px 16px;
    border-radius: 16px;

    &:not(:last-child) {
      margin-bottom: 0px;

      @media (min-width: 428px) {
        margin-bottom: 32px;
      }

      @media (min-width: 1024px) {
        margin-bottom: 44px;
        padding: 24px 16px;
        border-radius: 16px;
      }

      @media (min-width: 1280px) {
        margin-bottom: 0px;
      }

    }

    @media (min-width: 428px) {
      background: $graybgc;
      box-shadow: none;
    }
  }

  &__icon {
    margin-bottom: 16px;
    width: 88px;
    height: 88px;

    @media (max-width: 1024px) {
      width: 61px;
      height: 61px;
    }

    @media (max-width: 428px) {
      width: 48px;
      height: 48px;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0em;

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 24px;
    }

    @media (max-width: 428px) {
      font-size: 36px;
      line-height: 36px;
    }
  }

  &__subtitle {
    font-size: 24px;
    line-height: 30px;
    color: $grayText;
    letter-spacing: 0em;

    @media (max-width: 1024px) {
      line-height: 28px;
    }

    @media (max-width: 428px) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  // мобильная карусель
  &__carousel {
    display: none;
    margin-bottom: 32px;

    @media (max-width: 428px) {
      display: block;
    }
  }
}

.termsSwiper {
  width: calc(100% + 16px);
  max-width: 1024px;
  overflow: clip;
  overflow-y: visible;
  height: 100%;

  @media (max-width: 428px) {
    overflow-x: visible
  }

}

.swiper-slide:first-child {
  margin-left: 3px;
}

.termsSwiper .swiper-slide {
  height: 356px;
  width: 300px;

  @media (min-width: 1280px) {
    height: 426px;
  }
}
